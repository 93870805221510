import https from "./https"

const role = {
  getRoles (orgId, params) {
    return https.get(`admin/organizations/${orgId}/roles`, { params })
  },
  getRole (orgId, roleId) {
    return https.get(`admin/organizations/${orgId}/roles/${roleId}`)
  },
  updateRole (orgId, role) {
    return https.put(`admin/organizations/${orgId}/roles/${role.id}`, role)
  },
  removeRole (orgId, role) {
    const url = 'admin/organizations/' + orgId + '/roles/' + role.id;
    return https.delete(url)
  },
  storeRole (orgId, role) {
    const url = `admin/organizations/${orgId}/roles`
    return https.post(url, role)
  },
  syncPermissions (orgId, role, params) {
    const url = `admin/organizations/${orgId}/roles/${role.id}/permissions`
    return https.put(url, params)
  },
  getSubordinate (orgId) {
    return https.get(`admin/organizations/${orgId}/roles/subordinate`)
  },
  getStaffs (orgId, roleId) {
    return https.get(`admin/organizations/${orgId}/roles/${roleId}/staffs`)
  },
}

export default role

<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-for="breadcrumb in breadcrumbs"
              :to="breadcrumb.to"
              :key="breadcrumb.name"
            >{{ breadcrumb.name }}</b-breadcrumb-item>

            <b-breadcrumb-item active>角色相關帳號</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{ organization.name }}角色相關帳號
              </h4>

              <role-form :role="role" :is-read-only="true" ref="form"></role-form>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <b-overlay :show="showLoading" rounded="sm">
                  <b-table striped hover responsive :items="staffs" :fields="fields">
                    <template #cell(name)="data">
                      {{ data.item.name | hiddenString(20) }}
                    </template>
                    <template #cell(action)="data">
                      <b-dropdown id="ddown23" variant="secondary">
                        <template slot="button-content">
                          <i class="mdi mdi-menu"></i>
                        </template>
                        <b-dropdown-item
                          :to="{
                            name: 'OrganizationsStaffChangePassword',
                            params: { role_id: role.id, staff_id: data.item.id },
                          }"
                        >
                          變更密碼
                        </b-dropdown-item>
                        <b-dropdown-item @click="simulateLogin(data.item)">
                          模擬登入
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </b-overlay>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                :to="getRoleListLink()"
                variant="outline-danger"
              >返回
              </b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
    <b-modal id="simulate-login" footer-class="d-flex justify-content-center" hide-header>
      <div class="swal2-icon swal2-warning swal2-animate-warning-icon" style="display:flex">
        <span class="swal2-icon-text">!</span>
      </div>
      <div>切換帳號：{{ selectedStaff.employee_code }}</div>
      <div>登入網址：<b-button variant="success" @click="generateLoginUrl">重新產生</b-button></div>
      <b-textarea
        id="simulate-login-url"
        v-b-tooltip.click="'已複製'"
        v-model="simulateLoginUrl"
        @click="copySimulateLoginUrl"
        class="mt-2"
      ></b-textarea>
      <template #modal-footer>
        <b-button size="lg" variant="danger" @click="$bvModal.hide('simulate-login')">關閉</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import roleApi from "@/apis/role";
import organizationApi from "@/apis/organization";
import superuserStaffApi from "@/apis/superuser/staff";
import RoleForm from "./RoleForm"
import { mapGetters } from "vuex";

export default {
  components: { RoleForm },
  data() {
    return {
      showLoading: false,
      role: {
        name: null,
        code: null,
        description: null,
      },
      staffs: [],
      organization: this.$store.state.general.organization,
      selectedStaff: {
        employee_code: null,
      },
      simulateLoginUrl: null,
    };
  },
  computed: {
    ...mapGetters("route", ["routeParams", "routeQuery"]),
    breadcrumbs() {
      if (this.$route.name == 'OrgRoleStaffList') {
        return [
          { to: { name: 'SystemSettingsHome' }, name: '全系統管理' },
          { to: { name: 'OrganizationList' }, name: '組織管理' },
          { to: { name: 'OrgRoleList', params: { orgId: this.organization.id } }, name: '角色列表' },
        ]
      }

      return [
        { to: { name: 'RoleList' }, name: '角色列表' },
      ]
    },
    fields() {
      let fields = [
        { key: "employee_code", label: "員工編號", sortable: true },
        { key: "name", label: "姓名" },
        { key: "mobile_phone", label: "手機" },
        { key: "email", label: "E-Mail" },
        { key: "display_status", label: "狀態" },
      ];

      if (this.$route.name == 'OrgRoleStaffList') {
        fields.push({ key: "action", label: "操作" })
      }

      return fields;
    },
  },
  async mounted() {
    if (this.$route.name == 'OrgRoleStaffList' && this.$route.params.orgId) {
      await this.fetchOrg();
    }
    await this.fetchRole();
    await this.fetchStaffs();
    console.log(this.routeParams)
  },
  methods: {
    async fetchRole() {
      const result = await roleApi.getRole(this.organization.id, this.$route.params.role_id);
      this.role = result.data.data;
      this.checkedPermissions = this.role.permissions.map(permission => permission.id)
    },
    async fetchStaffs() {
      const result = await roleApi.getStaffs(this.organization.id, this.$route.params.role_id);
      this.staffs = result.data.data;
      const now = new Date();
      this.staffs.forEach((staff) => {
        staff.display_status = staff.is_enabled ? "啟用" : "未啟用";
        if (staff.left_at) {
          const leftAt = new Date(staff.left_at);
          staff.display_status =
            leftAt.getTime() > now.getTime() ? staff.display_status : "離職";
        }
      });
    },
    async fetchOrg() {
      const { data } = await organizationApi.getOrganizationById(this.$route.params.orgId);
      this.organization = data.data;
    },
    getRoleListLink() {
      if (this.$route.name == 'OrgRoleStaffList') {
        if (this.routeParams) {
          return { name: 'OrgRoleList', params: this.routeParams, query: this.routeQuery }
        } else {
          return { name: 'OrgRoleList', params: { orgId: this.organization.id }, };
        }
      }
      return { name: 'RoleList' }
    },
    async simulateLogin(staff) {
      this.selectedStaff = staff
      this.generateLoginUrl()
      this.$bvModal.show('simulate-login')
    },
    async generateLoginUrl() {
      this.showLoading = true
      const response = await superuserStaffApi.getLoginToken(this.selectedStaff.id);
      this.simulateLoginUrl = `https://${window.location.host}/${this.organization.code}/auth/login-by-token?token=${response.data.data.token}`
      this.showLoading = false
    },
    copySimulateLoginUrl() {
      const inputText = document.querySelector('#simulate-login-url');
      inputText.select();
      document.execCommand('copy');
    },
  }
};
</script>

import https from "@/apis/https"

const staff = {
  getOneStaff(staffId) {
    return https.get('superuser/staffs/' + staffId)
  },
  changePassword(staffId, params) {
    const url = 'superuser/staffs/' + staffId + '/change-password'

    return https.put(url, params)
  },
  getLoginToken(staffId) {
    return https.get(`superuser/staffs/${staffId}/login-token`)
  },
}

export default staff
